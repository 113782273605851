import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from "@ngrx/store";
import { TemplateListModel } from "@ssi/fusion";
import { produce } from "immer";
import { loadEffect } from "./load.effect";
import { deleteEffect } from "./delete.effect";
import { copyEffect } from "./copy.effect";
import { addEffect } from "./add.effect";
import { filterTemplateList } from "./filter-template-list.fn";
import { TemplateListState, TemplateSearch, TemplateSort } from "./template-list.types";
import { OwnerInfo } from '../../pages/template-list/template-search/template-search.component';
import { loadOwnersEffect } from './load-owners.effect';

const defaultTemplateListState: TemplateListState = {
  list: [],
  sort: { sortField: 'updatedAt', direction: 'descending' },
  search: { byReleaseStatus: 'all' },
  loaded: false,
  ownerList: []
};

export const templateListActions = createActionGroup({
  source: 'Template List',
  events: {
    'Fetch Init': emptyProps(),
    'Fetch Success': props<{ list: TemplateListModel[] }>(),
    'Fetch Owners': emptyProps(),
    'Fetch Owners Success': props<{ list: OwnerInfo[] }>(),
    'Sort Update': props<{ sort: TemplateSort }>(),
    'Search Update': props<{ search: TemplateSearch }>(),
    'Delete': props<{ templateId: string, userUuid: string }>(),
    'Delete Success': props<{ templateId: string }>(),
    'Copy': props<{ templateId: string, userUuid: string }>(),
    'Add': props<{ userUuid: string }>()
  }
});

export const templateListFeature = createFeature({
  name: 'templateList',
  reducer: createReducer(
    defaultTemplateListState,
    on(templateListActions.sortUpdate,
      (state, { sort }) => produce(state, draft => {
        draft.sort = sort;
      })),
    on(templateListActions.searchUpdate,
      (state, { search }) => produce(state, draft => {
        draft.search = search;
      })),
    on(templateListActions.fetchInit,
      state => produce(state, draft => {
        draft.loaded = false;
      })),
    on(templateListActions.fetchSuccess,
      (state, { list }) => produce(state, draft => {
        draft.list = list;
        draft.loaded = true;
      })),
    on(templateListActions.fetchOwnersSuccess,
      (state, { list }) => produce(state, draft => {
        draft.ownerList = list;
        draft.loaded = true;
      })),
    on(templateListActions.deleteSuccess, (state, { templateId }) => {
      const filteredList = state.list.filter(template => template.templateId !== templateId);
      return produce(state, draft => {
        draft.list = filteredList;
        draft.loaded = true;
      });
    })
  ),
  extraSelectors: ({ selectList, selectSort, selectSearch }) => ({
    filterTemplateList: createSelector(
      selectList,
      selectSearch,
      selectSort,
      (list, search, sort) => filterTemplateList(list, search, sort)
    )
  })
});
export const templateListEffects = { loadEffect, deleteEffect, copyEffect, addEffect, loadOwnersEffect };
