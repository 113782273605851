import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { map, withLatestFrom } from "rxjs";
import { templateEditFeature } from "../template-edit/template-edit";

export const updateParameterStatusEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(templateParametersActions.updateParameterStatus),
      withLatestFrom(store.select(templateParametersFeature.selectUserParameterItemList),
        store.select(templateParametersFeature.selectNormalParameterItemList),
        store.select(templateEditFeature.selectTemplate)),
      map(([ action, userParams, normalParams, template ]) => {
        const { info } = action;
        const newUserParams = [ ...userParams ];
        const newNormalParams = [ ...normalParams ];

        if (info.paramType === 'user') {
          newUserParams[info.index] = { ...newUserParams[info.index], enabled: info.enabled };
        } else {
          newNormalParams[info.index] = { ...newNormalParams[info.index], enabled: info.enabled };
        }
        return templateParametersActions.updateParametersInit({
          'info': {
            templateId: template?.templateId || '',
            userParameterItemList: newUserParams,
            normalParameterItemList: newNormalParams
          }
        });
      })),
  { functional: true }
);
