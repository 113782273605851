import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { TemplateAnalyticsService } from "../../pages/analytics/services/template-analytics.service";
import { analyticsUsageActions } from "./analytics-usage";
import { map, switchMap } from "rxjs";

export const fetchUserTypesEffect = createEffect(
  (actions$ = inject(Actions), analyticsService = inject(TemplateAnalyticsService)) => {
    return actions$.pipe(
      ofType(analyticsUsageActions.loadUserTypesInit),
      switchMap(() => analyticsService.getUserTypes()),
      map(types => analyticsUsageActions.loadUserTypesSuccess({ types }))
    );
  },
  { functional: true }
);
