import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./pages/home/home.component";
import { PostSignInComponent } from "./pages/post-sign-in/post-sign-in.component";
import { RestrictedGuard } from "./guards/restricted.guard";
import { UnrestrictedGuard } from "./guards/unrestricted.guard";
import { PostSignOutComponent } from "./pages/post-sign-out/post-sign-out.component";

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent, canActivate: [UnrestrictedGuard] },
  { path: 'post-sign-in', component: PostSignInComponent },
  { path: 'post-sign-out', component: PostSignOutComponent },
  {
    path: 'template-list',
    canActivate: [RestrictedGuard],
    loadChildren: () => import('./pages/template-list/template-list.module').then(m => m.TemplateListModule)
  },
  {
    path: 'edit-template',
    canActivate: [RestrictedGuard],
    loadChildren: () => import('./pages/edit-template/edit-template.module').then(m => m.EditTemplateModule)
  },
  {
    path: 'help',
    canActivate: [RestrictedGuard],
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'analytics',
    canActivate: [RestrictedGuard],
    loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
