import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { TemplateService } from "../../services/template.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { templateListActions } from "./template-list";
import { catchError, EMPTY, filter, map, switchMap, tap } from "rxjs";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData
} from "../../dialogs/confirmation-dialog/confirmation-dialog.component";

export const deleteEffect = createEffect(
  (actions$ = inject(Actions), templateService = inject(TemplateService),
   matSnackBar = inject(MatSnackBar), dialog = inject(MatDialog)) => {
    return actions$.pipe(
      ofType(templateListActions.delete),
      switchMap(({ templateId, userUuid }) => {
        const dialogData: ConfirmationDialogData = {
          title: 'Delete Template?',
          messages: [ 'Are you sure you want to delete this template?' ],
          data: { templateId, userUuid }
        };
        const dialogRef = dialog.open(ConfirmationDialogComponent, { data: dialogData });
        return dialogRef.afterClosed();
      }),
      filter(returnVal => returnVal !== undefined),
      switchMap(data => templateService.deleteTemplate(data.templateId, data.userUuid)),
      tap(() => {
        matSnackBar.open('Template successfully deleted', 'Close', { duration: 2000 });
      }),
      map(template => templateListActions.deleteSuccess({ templateId: template.templateId })),
      catchError(() => {
        matSnackBar.open('An error occurred deleting the template', 'Close', { duration: 2000 });
        return EMPTY;
      })
    );
  }, { functional: true });
