import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { getRouterSelectors, routerNavigatedAction } from "@ngrx/router-store";
import { distinctUntilChanged, filter, map, switchMap, withLatestFrom } from "rxjs";
import { Data } from "@angular/router";
import { RouteNames } from "../../app.constants";
import { analyticsUsageActions, analyticsUsageFeature } from "./analytics-usage";
import { emptyAction } from "../store";

export const enterAnalyticsRawDataRouteEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    const { selectRouteData, selectQueryParams } = getRouterSelectors();
    return actions$.pipe(
      ofType(routerNavigatedAction),
      distinctUntilChanged(),
      switchMap(() => store.select(selectRouteData)),
      filter((data: Data) => data['routeName'] === RouteNames.AnalyticsRawData),
      switchMap(() => store.select(selectQueryParams)),
      withLatestFrom(store.select(analyticsUsageFeature.selectRawData)),
      map(([ params, rawData ]) => {
        if (!params['templateId']) return emptyAction();
        const updatedFilters = { ...rawData.filters, templateId: params['templateId'] };
        return analyticsUsageActions.updateRawFilters({ filters: updatedFilters });
      })
    );
  },
  { functional: true }
);
