import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { AuthTokenService } from "../../services/auth-token.service";
import { userActions } from "./user";
import { catchError, EMPTY, map, switchMap } from "rxjs";

export const loadUserEffect = createEffect(
  (actions$ = inject(Actions), tokenService = inject(AuthTokenService)) =>
    actions$.pipe(
      ofType(userActions.load),
      switchMap(() => tokenService.getUserInfo()),
      map(userInfo => userActions.loadSuccess({ userUuid: userInfo.userUuid })),
      catchError(() => EMPTY)
    ), { functional: true });
