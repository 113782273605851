import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { map, withLatestFrom } from "rxjs";
import { templateEditFeature } from "../template-edit/template-edit";

export const updateNormalParametersEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => actions$.pipe(
    ofType(templateParametersActions.updateNormalParameters),
    withLatestFrom(store.select(templateParametersFeature.selectUserParameterItemList),
      store.select(templateEditFeature.selectTemplate)),
    map(([ action, userItems, template ]) =>
      templateParametersActions.updateParametersInit({
        info: {
          normalParameterItemList: action.items,
          userParameterItemList: userItems,
          templateId: template?.templateId || ''
        }
      }))
  ),
  { functional: true }
);
