import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { BrowserStorage } from "../../app.models";
import { Store } from "@ngrx/store";
import { templateEditActions, templateEditFeature } from "./template-edit";
import { map, withLatestFrom } from "rxjs";
import { Ace } from "../../app.constants";
import { AceEditorConfig } from "./template-edit.types";

export const loadAceConfigEffect = createEffect(
  (actions$ = inject(Actions), storage = inject(BrowserStorage), store = inject(Store)) => {
    return actions$.pipe(
      ofType(templateEditActions.loadAceConfigInit),
      withLatestFrom(store.select(templateEditFeature.selectTemplateEditState)),
      map(([ _, state ]) => {
        let keyboardName = storage.getItem(Ace.keyboardStorageKey);
        if (!keyboardName) {
          storage.setItem(Ace.keyboardStorageKey, Ace.defaultKeyboard);
          keyboardName = Ace.defaultKeyboard;
        }
        const sqlMode = (state.template?.type || 'agvance') === 'sky' ? 'pgsql' : 'sqlserver';
        const readonly = (state.template?.releasedAt || 0) > 0;
        const config = { ...state.aceEditorConfig, keyboard: keyboardName, sqlMode, readonly } as AceEditorConfig;
        return templateEditActions.loadAceConfigSuccess({ config });
      })
    );
  },
  { functional: true }
);
