import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { BrowserStorage } from "../../app.models";
import { templateEditActions } from "./template-edit";
import { map } from "rxjs";
import { Storage } from "../../app.constants";

export const loadSavedCompanyUuidEffect = createEffect(
  (actions$ = inject(Actions), storage = inject(BrowserStorage)) => actions$.pipe(
    ofType(templateEditActions.loadTemplateInit),
    map(() => {
      const companyUuid = storage.getItem(Storage.MssqlCompanyId) || '';
      return templateEditActions.companyUuidSuccess({ companyUuid });
    })
  ),
  { functional: true }
);
