import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { filter, forkJoin, map, of, switchMap, withLatestFrom } from "rxjs";
import { templateEditFeature } from "../template-edit/template-edit";
import {
  TemplateParameterDialogComponent
} from "../../dialogs/template-parameter-dialog/template-parameter-dialog.component";

export const editParameterEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), dialog = inject(MatDialog)) =>
    actions$.pipe(
      ofType(templateParametersActions.editParameter),
      withLatestFrom(store.select(templateEditFeature.selectTemplate),
        store.select(templateParametersFeature.selectNormalParameterItemList),
        store.select(templateParametersFeature.selectUserParameterItemList)),
      switchMap(([ action, template, normalParams, userParams ]) => {
        const { info } = action;
        const foundParam = info.paramType === 'user' ? userParams[info.index] : normalParams[info.index];
        const dialogRef = dialog.open(TemplateParameterDialogComponent, { data: foundParam });
        return forkJoin({
          dialogData: dialogRef.afterClosed(), actionInfo: of(info), template: of(template),
          userParams: of(userParams), normalParams: of(normalParams)
        });
      }),
      filter(({ dialogData }) => dialogData !== undefined),
      map(({ dialogData, template, userParams, normalParams, actionInfo }) => {
        const newUserParams = [ ...userParams ];
        const newNormalParams = [ ...normalParams ];
        if (actionInfo.paramType === 'normal') newNormalParams[actionInfo.index] = dialogData;
        if (actionInfo.paramType === 'user') newUserParams[actionInfo.index] = dialogData;
        return templateParametersActions.updateParametersInit({
          info: {
            templateId: template?.templateId || '',
            userParameterItemList: newUserParams,
            normalParameterItemList: newNormalParams
          }
        });
      })),
  { functional: true }
);
