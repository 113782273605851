import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { TemplateAnalyticsService } from "../../pages/analytics/services/template-analytics.service";
import { analyticsUsageActions, analyticsUsageFeature } from "./analytics-usage";
import { distinctUntilChanged, map, switchMap, withLatestFrom } from "rxjs";

export const fetchRawDataNextPageEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), service = inject(TemplateAnalyticsService)) => {
    return actions$.pipe(
      ofType(analyticsUsageActions.fetchRawDataNextPage),
      distinctUntilChanged(),
      withLatestFrom(store.select(analyticsUsageFeature.selectRawData)),
      switchMap(([ _, rawData ]) => {
        const filters = rawData.filters;
        return Promise.all([ service.getRawData(filters, rawData.continuationToken), rawData ]);
      }),
      map(([ info, rawData ]) => {
        const expandedResults = [ ...rawData.results, ...info.data ];
        return analyticsUsageActions.updateRawDataSuccess({ info: { ...info, data: expandedResults } });
      })
    );
  },
  { functional: true }
);
