import { Component } from '@angular/core';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { UserState } from "../../store/user/user.types";
import { userActions, userFeature } from "../../store/user/user";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  user$: Observable<UserState>;

  constructor(private readonly store: Store) {
    this.user$ = this.store.select(userFeature.selectUserState);
  }

  signIn(): void {
    this.store.dispatch(userActions.signIn());
  }
}
