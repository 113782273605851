import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { map, withLatestFrom } from "rxjs";
import { templateEditFeature } from "../template-edit/template-edit";
import { emptyAction } from "../store";
import { uniq } from "lodash";
import { ParameterItem } from "@ssi/fusion";

export const autofillNormalParametersEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => actions$.pipe(
    ofType(templateParametersActions.autofillNormalParameters),
    withLatestFrom(store.select(templateEditFeature.selectTemplate),
      store.select(templateParametersFeature.selectNormalParameterItemList)),
    map(([ _, template, paramList ]) => {
      if (!template) return emptyAction();
      const content = template.content || '';
      const matches = content.match(/@\w+/gu) || [];
      if (matches.length === 0) return emptyAction();

      const paramNames = matches.map(word => word.substring(1));
      const existingParams = paramList.map(item => item.name);
      const uniqueNames = uniq(paramNames).filter(name => !existingParams.includes(name));
      if (uniqueNames.length === 0) return emptyAction();

      const newItems = uniqueNames.map(name => {
        return { name, enabled: true, type: 'varchar', value: '', scope: 'both' } as ParameterItem;
      });
      return templateParametersActions.updateNormalParameters({ items: newItems });
    })
  ),
  { functional: true }
);
