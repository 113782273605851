<h2>Welcome to the Fusion Website</h2>

<p>SKY developers will need to sign in using active directory to view and edit templates.</p>

<ng-container *ngIf="user$ | async as user">
  <ng-container *ngIf="!user.isSignedIn">
    <p>
      <button mat-flat-button color="primary" (click)="signIn()">Sign In</button>
    </p>
  </ng-container>
</ng-container>
