import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { BrowserLocation } from "../../app.models";
import { userActions } from "./user";
import { distinctUntilChanged, tap } from "rxjs";
import { Cognito } from "../../app.constants";

export const signInEffect = createEffect(
  (actions$ = inject(Actions), browserLocation = inject(BrowserLocation)) =>
    actions$.pipe(
      ofType(userActions.signIn),
      distinctUntilChanged(),
      tap(() => {
        const currentUrl = new URL(browserLocation.toString());
        const redirectUri = `${currentUrl.origin}/post-sign-in`;

        const cognitoUrl = new URL(`${Cognito.url}/oauth2/authorize`);
        cognitoUrl.searchParams.set('client_id', Cognito.clientId);
        cognitoUrl.searchParams.set('redirect_uri', redirectUri);
        cognitoUrl.searchParams.set('response_type', Cognito.responseType);
        cognitoUrl.searchParams.set('identity_provider', Cognito.identityProvider);
        cognitoUrl.searchParams.set('scope', 'openid email profile');
        browserLocation.replace(cognitoUrl.toString());
      })
    ), { functional: true });
