import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { userActions, userFeature } from "./store/user/user";
import { UserState } from "./store/user/user.types";
import { sharedActions } from "./store/shared.effects";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user$: Observable<UserState>;

  constructor(private readonly store: Store) {
    this.store.dispatch(userActions.load());
    this.user$ = this.store.select(userFeature.selectUserState);
  }

  signIn(): void {
    this.store.dispatch(userActions.signIn());
  }

  signOut(): void {
    this.store.dispatch(userActions.signOut());
  }

  navigate(paths: string[]): void {
    this.store.dispatch(sharedActions.navigate({ paths }));
  }
}
