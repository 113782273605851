import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { TemplateAnalyticsService } from "../../pages/analytics/services/template-analytics.service";
import { analyticsUsageActions } from "./analytics-usage";
import { distinctUntilChanged, map, switchMap } from "rxjs";
import { orderBy } from "lodash";
import { AnalyticsUsageResult } from "./analytics-usage.types";

export const fetchUsageEffect = createEffect(
  (actions$ = inject(Actions), analyticsService = inject(TemplateAnalyticsService)) => {
    return actions$.pipe(
      ofType(analyticsUsageActions.updateFilters),
      distinctUntilChanged(),
      switchMap(({ filters }) => {
        return Promise.all([ filters, analyticsService.getUsageStatistics(filters) ]);
      }),
      switchMap(([ filters, usageResults ]) => {
        if (!filters.showExcluded) return Promise.all([ filters, usageResults, [] ]);

        const idList = usageResults.map(usageResult => usageResult.templateId);
        return Promise.all([ filters, usageResults, analyticsService.getExcludedUsageStatistics(filters, idList) ]);
      }),
      map(([ filters, usageResults, excludedResults ]) => {
        const [ field, direction ] = filters.order.split('_');
        const allResults = orderBy([ ...usageResults, ...excludedResults ], [ field ], [ direction as any ]);
        return analyticsUsageActions.usageResultsSuccess({ usageResults: allResults as AnalyticsUsageResult[] });
      })
    );
  }, { functional: true }
);
