import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { userActions } from "../../store/user/user";

@Component({
  selector: 'app-post-sign-in',
  templateUrl: './post-sign-in.component.html',
  styleUrls: ['./post-sign-in.component.scss']
})
export class PostSignInComponent implements OnInit {

  constructor(private readonly store: Store) { }

  ngOnInit(): void {
    const currentUrl = new URL(window.location.toString());

    if (!currentUrl.searchParams.has('code')) {
      this.store.dispatch(userActions.signInFailure());
    } else {
      const authCode = currentUrl.searchParams.get('code') || '';
      this.store.dispatch(userActions.signInSuccess({ authCode }));
    }

  }

}
