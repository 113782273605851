import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { BrowserLocation, BrowserStorage } from "../../app.models";
import { userActions } from "./user";
import { distinctUntilChanged, tap } from "rxjs";
import { Cognito, Storage } from "../../app.constants";

export const signOutEffect = createEffect(
  (actions$ = inject(Actions), browserStorage = inject(BrowserStorage), browserLocation = inject(BrowserLocation)) =>
    actions$.pipe(
      ofType(userActions.signOut),
      distinctUntilChanged(),
      tap(() => {
        browserStorage.removeItem(Storage.IdToken);
        browserStorage.removeItem(Storage.AccessToken);
        browserStorage.removeItem(Storage.RefreshToken);

        const currentUrl = new URL(browserLocation.toString());
        const logoutUri = `${currentUrl.origin}/post-sign-out`;

        const url = new URL(`${Cognito.url}/logout`);
        url.searchParams.set('client_id', Cognito.clientId);
        url.searchParams.set('logout_uri', logoutUri);
        browserLocation.replace(url.toString());
      })
    ), { functional: true });
