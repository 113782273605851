import { ExecuteTemplateData, FusionClient, ParameterItem } from "@ssi/fusion";
import { Injectable } from '@angular/core';
import { FusionTemplates } from '../app.constants';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class UserTemplateParametersService {

  constructor(private readonly fusionClient: FusionClient, private readonly utilityService: UtilityService) { }

  getSavedParameters(templateId: string, userId: string): Promise<ParameterItem[]> {
    return this.getSavedParameterEntry(templateId, userId).then(res => res ? res.parameterItems : []);
  }

  async saveParameters(templateId: string, userId: string, parameterItems: ParameterItem[]): Promise<void> {
    const entry = await this.getSavedParameterEntry(templateId, userId);
    const entryId = entry && entry.userId === userId ? entry.id : this.utilityService.uuid();

    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.SaveTemplateParams,
      parameters: {
        entryId: { type: 'varchar', value: entryId, scope: 'sql' },
        templateId: { type: 'varchar', value: templateId, scope: 'sql' },
        userId: { type: 'varchar', value: userId, scope: 'sql' },
        parameterItems: { type: 'varchar', value: JSON.stringify(parameterItems), scope: 'sql' }
      }
    };
    return this.fusionClient.send<any>(templateData).then();
  }

  private getSavedParameterEntry(templateId: string, userId: string): Promise<ParameterEntry | undefined> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.GetTemplateParams,
      parameters: {
        templateId: { type: 'varchar', value: templateId, scope: 'sql' },
        userId: { type: 'varchar', value: userId, scope: 'sql' }
      }
    };
    return this.fusionClient.send<ParameterEntry>(templateData).then(res => res.data || undefined);
  }
}

interface ParameterEntry {
  id: string;
  userId: string;
  templateId: string;
  parameterItems: ParameterItem[];
}
