import { Injectable } from '@angular/core';
import { ExecuteTemplateData, FusionClient, PaginatedTemplateData, TemplateParams } from "@ssi/fusion";
import * as dayjs from "dayjs";
import { FusionTemplates } from "../../../app.constants";
import { FusionEnvironment } from "../../../app.models";

import {
  AnalyticsRawInfo,
  AnalyticsRawInfoBase,
  CloudwatchSearchDto,
  PaginatedInfo,
  UserTypeResult
} from "../analytics.types";
import {
    AnalyticsRawFilters,
    AnalyticsUsageFilters,
    AnalyticsUsageResult
} from "../../../store/analytics-usage/analytics-usage.types";

@Injectable({ providedIn: 'root' })
export class TemplateAnalyticsService {

  constructor(private readonly fusionClient: FusionClient) { }

  public getUsageStatistics(filters: AnalyticsUsageFilters): Promise<AnalyticsUsageResult[]> {
    const params = {
      "dateRangeStart": {
        "type": "bigint", "scope": "both",
        "value": dayjs(filters.dateRangeStart).startOf('day').toDate().getTime()
      },
      "dateRangeEnd": {
        "type": "bigint", "scope": "both",
        "value": dayjs(filters.dateRangeEnd).endOf('day').toDate().getTime()
      }
    } as TemplateParams;
    if (filters.environment !== 'any') {
      params['environment'] = { "type": "varchar", "value": filters.environment, "scope": "both" };
    }
    if (filters.application !== 'any') {
      params['application'] = { "type": "varchar", "value": filters.application, "scope": "both" };
    }
    if (filters.resultType !== 'any') {
      params['resultType'] = { "type": "varchar", "value": filters.resultType, "scope": "ejs" };
    }
    if (filters.releaseStatus !== 'any') {
      params['releaseStatus'] = { "type": "varchar", "value": filters.releaseStatus, "scope": "ejs" };
    }
    if (filters.name !== '') {
      params['search'] = { "type": "varchar", "value": filters.name, "scope": "ejs" };
    }
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.AnalyticsUsage,
      parameters: params
    };
    return this.fusionClient.send<AnalyticsUsageResult[]>(templateData).then(result => result.data || []);
  }

  public getExcludedUsageStatistics(filters: AnalyticsUsageFilters, idList: string[]): Promise<AnalyticsUsageResult[]> {
    const templateIdList = idList.map(id => `'${id}'`).join(',');
    const params = {
      "templateIdList": { "type": "varchar", "value": templateIdList, "scope": "ejs" }
    } as TemplateParams;
    if (filters.releaseStatus !== 'any') {
      params['releaseStatus'] = { "type": "varchar", "value": filters.releaseStatus, "scope": "ejs" };
    }
    if (filters.name !== '') {
      params['search'] = { "type": "varchar", "value": filters.name, "scope": "ejs" };
    }
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.AnalyticsExcluded,
      parameters: params
    };
    return this.fusionClient.send<AnalyticsUsageResult[]>(templateData).then(res => res.data || []);
  }

  public getApplicationList(): Promise<{ application: string }[]> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.AnalyticsApplicationList,
      cacheConfiguration: {
        key: 'analytics-application-list', duration: { unit: 'minute', value: 5 }
      }
    };
    return this.fusionClient.send<{ application: string }[]>(templateData).then(res => res.data || []);
  }

  public getCloudwatchSearchUrl(dto: CloudwatchSearchDto): string {
    let env = dto.environment === 'production' ? 'Prod' : 'Dev';
    env = dto.environment === 'test' ? 'Test' : env;
    const start = dayjs(dto.invocationTime).subtract(2, 'minute').valueOf();
    const end = dayjs(dto.invocationTime).add(2, 'minute').valueOf();
    const urlParts = [
      'https://us-east-1.console.aws.amazon.com/cloudwatch/home',
      '?region=us-east-1#logsV2:log-groups/log-group',
      `/$252Faws$252Flambda$252FFusionFn-${env}`,
      `/log-events$3FfilterPattern$3D$2522${dto.requestId}$2522`,
      `$26start$3D${start}$26end$3D${end}`
    ];
    return urlParts.join('');
  }

  public getRawData(filters: AnalyticsRawFilters, continuationToken = ''): Promise<PaginatedInfo<AnalyticsRawInfo>> {
    const params: TemplateParams = {
      "dateRangeStart": {
        "type": "bigint", "value": dayjs(filters.dateRangeStart).startOf('day').valueOf()
      },
      "dateRangeEnd": {
        "type": "bigint", "value": dayjs(filters.dateRangeEnd).endOf('day').valueOf()
      },
      "orderField": { "type": "varchar", "value": filters.order.split('_')[0], "scope": "ejs" },
      "orderDirection": { "type": "varchar", "value": filters.order.split('_')[1], "scope": "ejs" },
    };
    if (filters.templateId !== '') {
      params['templateId'] = { "type": "varchar", "value": filters.templateId };
    }
    if (filters.environment !== 'any') {
      params['environment'] = { "type": "varchar", "value": filters.environment };
    }
    if (filters.application !== 'any') {
      params['application'] = { "type": "varchar", "value": filters.application };
    }
    if (filters.resultType !== 'any') {
      params['resultType'] = { "type": "varchar", "value": filters.resultType, scope: 'ejs' };
    }
    if (filters.userUuid !== '') {
      params['userUuid'] = { "type": "varchar", "value": filters.userUuid, scope: 'ejs' };
    }
    const templateData: PaginatedTemplateData = {
      templateId: FusionTemplates.AnalyticsRawData, parameters: params, pageSize: 50, continuationToken
    };
    return this.fusionClient.sendPaginated<AnalyticsRawInfoBase>(templateData).then(result => {
        const data = result.data || [];
        const mappedData: AnalyticsRawInfo[] = data.map(d => {
          const cloudwatchUrl = this.getCloudwatchSearchUrl({
            requestId: d.awsRequestId,
            environment: d.environment as FusionEnvironment,
            invocationTime: d.invocationTime
          });
          return { ...d, cloudwatchUrl };
        });
        return {
          continuationToken: result.continuationToken,
          data: mappedData
        } as PaginatedInfo<AnalyticsRawInfo>;
      });
  }

  public getUserTypes(): Promise<string[]> {
    return this.fusionClient.send<UserTypeResult[]>({
      templateId: FusionTemplates.AnalyticsUserTypes,
      cacheConfiguration: {
        key: 'analytics-user-types', duration: { unit: 'minute', value: 20 }
      }
    }).then(res => res.ok ? res.data || [] : [])
      .then(res => res.map(r => r.userType));
  }
}
