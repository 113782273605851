import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { filter, map, switchMap, withLatestFrom } from "rxjs";
import {
  TemplateParameterDialogComponent
} from "../../dialogs/template-parameter-dialog/template-parameter-dialog.component";
import { templateEditFeature } from "../template-edit/template-edit";

export const addParameterEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), dialog = inject(MatDialog)) =>
    actions$.pipe(
      ofType(templateParametersActions.addParameter),
      switchMap(() => {
        const dialogRef = dialog.open(TemplateParameterDialogComponent);
        return dialogRef.afterClosed();
      }),
      filter(data => data !== undefined),
      withLatestFrom(store.select(templateEditFeature.selectTemplate),
        store.select(templateParametersFeature.selectNormalParameterItemList),
        store.select(templateParametersFeature.selectUserParameterItemList)),
      map(([ item, template, normalParams, userParams ]) => {
        const isUserParam = item.name.startsWith('$') && item.name.endsWith('$');
        const newNormalParams = isUserParam ? [ ...normalParams ] : [ ...normalParams, item ];
        const newUserParams = isUserParam ? [ ...userParams, item ] : [ ...userParams ];
        return templateParametersActions.updateParametersInit({
          info: {
            templateId: template?.templateId || '',
            userParameterItemList: newUserParams,
            normalParameterItemList: newNormalParams
          }
        });
      })),
  { functional: true }
);
