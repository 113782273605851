import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { TemplateService } from "../../services/template.service";
import { templateEditActions } from "./template-edit";
import { map, switchMap } from "rxjs";

export const loadTemplateInitEffect = createEffect(
  (actions$ = inject(Actions), templateService = inject(TemplateService)) => actions$.pipe(
    ofType(templateEditActions.loadTemplateInit),
    switchMap(({ templateId }) => templateService.getTemplate(templateId)),
    map(template => templateEditActions.loadTemplateSuccess({ template }))
  ),
  { functional: true }
);
