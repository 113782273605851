import { FusionEnvironment } from "./app.models";

export const Environment: FusionEnvironment = 'development';
export const AwsImageBucket = 'fusionweb-resources';
export const AwsDefaultVideoType = 'video/mp4';
export const AwsPresignedUrlTimeout = 60 * 60; // 60 minutes
export const AwsS3Info = 'QUtJQUpXNkdXSjdXWlJIRENLSEEvdHVWTVkrZkRCdlJHRnhkZDR0aXI0SFR6aTlxZnk5Sm52UXBhSDV6dS91cy1lYXN0LTE';
export const CloudwatchInfo = 'QUtJQVlOUDI3UlJNS1pPNkJOWEsvcVFqMHE1MUhNNnRLeTRDa3pPT0l0eTFyT3BwSkRJaEhEVURYbTJMaS91cy1lYXN0LTE';
export const CloudwatchGroup = `arn:aws:logs:us-east-1:578736327768:log-group:/aws/lambda/FusionFn-Dev`;
export const DefaultVideoWidth = 700;
export const DefaultVideoHeight = 400;
export const UserInputDebounceTime = 200;
export const SnackbarDuration = 3000;

export const RouteNames = {
  TemplateList: 'Template List',
  EditTemplate: 'Edit Template',
  AnalyticsRawData: 'Analytics Raw Data'
};

export const Storage = {
  IdToken: 'id_token',
  AccessToken: 'access_token',
  RefreshToken: 'refresh_token',
  MssqlCompanyId: 'mssql_company'
};

export const Messages = {
  NoToken: 'No token found in storage',
  TemplateInfoSaved: 'Template info was successfully saved',
  VersionInfoSaved: 'Version info was successfully saved',
  VersionReleased: 'Version was successfully released',
  ConfirmRelease: 'Are you sure you want to release this template? \n\nYou cannot edit a template after it has been released',
  TemplateIdCopied: 'Template ID has been copied to the clipboard',
  TemplateSaveError: 'An error occurred saving template data',
  ClipboardCopy: 'Item has been copied to the clipboard'
};

export const Ace = {
  keyboardStorageKey: 'ace_keyboard',
  defaultKeyboard: 'default',
  basePath: 'https://unpkg.com/ace-builds@latest/src-noconflict',
  theme: 'ace/theme/textmate'
};

export const SkyPgDatabases = [
  { name: 'Grower360', dev: 'grower360_dev', prod: 'grower360_production' },
  { name: 'Payment', dev: 'PaymentDev', prod: 'PaymentProd' },
  { name: 'SKYDentity', dev: 'skydentity-dev', prod: 'skydentity' },
  { name: 'Analytics Datasource Catalog', dev: 'AnalyticsDatasourceCatalog', prod: 'AnalyticsDatasourceCatalog' },
  { name: 'Analytics Trace', dev: 'AnalyticsTrace', prod: 'AnalyticsTrace' },
  { name: 'Applicator Filters', dev: 'ApplicatorFilters', prod: 'ApplicatorFilters' },
  { name: 'Cash Bids', dev: 'CashBidApiDev', prod: 'CashBidApiProd' },
  { name: 'Chat Messages', dev: 'ChatMessages', prod: 'ChatMessages' },
  { name: 'Crop Chemistry Location', dev: 'CropChemistryLocation', prod: 'CropChemistryLocation' },
  { name: 'Crop Filters', dev: 'CropFilters', prod: 'CropFilters' },
  { name: 'Dispatch Logging', dev: 'DispatchLogsDev', prod: 'DispatchLogsProd' },
  { name: 'Dispatch Priorities', dev: 'DispatchPriorities', prod: 'DispatchPriorities' },
  { name: 'Dispatch User Filters', dev: 'DispatchUserFilters', prod: 'DispatchUserFilters' },
  { name: 'Grower360 Logging', dev: 'grower360log_dev', prod: 'grower360log_production' },
  { name: 'Job Assignments', dev: 'JobAssignments', prod: 'JobAssignments' },
  { name: 'Location Filter Service', dev: 'LocationFilterService', prod: 'LocationFilterService' },
  { name: 'Mapping', dev: 'MappingDev', prod: 'MappingProd' },
  { name: 'Messaging', dev: 'messagingapi-dev', prod: 'messagingapi-prod' },
  { name: 'Notifications', dev: 'NotificationsDev', prod: 'NotificationsProd' },
  { name: 'Patronage', dev: 'PatronageDev', prod: 'PatronageProd' },
  { name: 'Permissions', dev: 'PermissionsDatabaseDev', prod: 'PermissionsDatabaseProd' },
  { name: 'Sky Applicator', dev: 'SkyApplicatorService', prod: 'SkyApplicatorService' },
  { name: 'Sky User Analytics', dev: 'SkyUserAnalyticsDatabaseDev', prod: 'SkyUserAnalyticsDatabaseProd' },
  { name: 'Sky Other', dev: 'SkyOther', prod: 'SkyOther' },
  { name: 'Sky Permissions', dev: 'SkyPermissionsDev', prod: 'SkyPermissionsProd' },
  { name: 'User Notification', dev: 'UserNotificationApiDev', prod: 'UserNotificationApiProd' },
  { name: 'Utility', dev: 'Utility', prod: 'Utility' },
  { name: 'Fusion', dev: 'fusion', prod: 'fusion' },
  { name: 'Sky Data Hub', dev: 'SkyDataHub', prod: 'SkyDataHub' }
];

export const FusionTemplates = {
  AnalyticsUsage: '5823a74d-e647-4d2e-98e6-dab3b1c1fa48',
  AnalyticsExcluded: '9bec96e6-c118-4aa5-9437-e02d5448d21b',
  AnalyticsApplicationList: 'efc7b377-d63e-48f0-a74b-686dd5322c07',
  AnalyticsRawData: '4836fa94-24f7-490d-b794-779baa20b160',
  AnalyticsUserTypes: 'be748e31-3f22-497b-a785-c1c485e332fc',
  ReleaseTemplate: '0de969c3-0c2d-4d5d-a063-0dca675bb32b',
  DeleteTemplate: '182ef912-4e57-4246-a193-93e2107469b2',
  GetTemplate: '8cc7d672-f010-4f5c-8839-bbfe5f5ca18c',
  UserSession: 'ca6424a7-305c-4fb5-acd1-9c00565faf2a',
  InsertTemplate: 'efc6e253-1b87-484a-880c-e1fb2e1bbaca',
  CopyTemplate: '65a45a79-af49-433b-9a84-9405ff6159a8',
  PatchTemplate: '69d99c77-20dc-40bf-81f4-dcdb18aed709',
  GetTemplateParams: 'c2f98381-9457-4a40-8ef9-1c2e9c28ba4e',
  SaveTemplateParams: '0868114c-453c-494a-ae82-fc8fddc2c0d4',
  ListTemplates: 'a8ed7cd5-c78f-4b7d-9626-54101357e4c9',
  TemplateOwnerList: 'a45cb5e5-c26e-4802-ba18-88704d8152f7'
};

export const Cognito = {
  url: 'https://sky-devs.auth.us-east-1.amazoncognito.com',
  clientId: '4lrprf3m2kppjm3mi7r815ssis',
  identityProvider: 'SSIAzureAD',
  responseType: 'code'
};

export const delay = (value: any, timeout = 1000): Promise<any> => {
  return new Promise((resolve) => {
    const timeoutRef = setTimeout(() => {
      clearTimeout(timeoutRef);
      resolve(value);
    }, timeout);
  });
};

export const whenDefined = (fn: () => any | undefined, timeout = 2000, polling = 50): Promise<any> => {
  return new Promise((resolve, reject) => {
    const start = Date.now();
    const interval = setInterval(() => {
      const val = fn();
      if (val !== undefined) {
        clearInterval(interval);
        resolve(val);
      }

      const now = Date.now();
      if (now - start >= timeout) {
        clearInterval(interval);
        reject(new Error('timeout'));
      }
    }, polling);
  });
};
