<mat-toolbar color="primary">
  <span>Fusion Web</span>
  <span class="spacer"></span>

  <ng-container *ngIf="user$ | async as user">

    <ng-container *ngIf="!user.isSignedIn">
      <button mat-icon-button matTooltip="Sign In" (click)="signIn()">
        <mat-icon>login</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="user.isSignedIn">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="navigate(['/template-list'])">Template List</button>
        <button mat-menu-item (click)="navigate(['/help'])">Help Center</button>
        <button mat-menu-item (click)="navigate(['/analytics/template-usage'])">Template Statistics</button>
        <button mat-menu-item (click)="navigate(['/analytics/template-invocations'])">Template Invocation Data</button>
        <button mat-menu-item (click)="signOut()">Sign Out</button>
      </mat-menu>
    </ng-container>
  </ng-container>

</mat-toolbar>
<div style="margin:1em">
  <router-outlet></router-outlet>
</div>
