import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TemplateService } from "../../services/template.service";
import { templateEditActions } from "./template-edit";
import { filter, map, switchMap } from "rxjs";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData
} from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import { emptyAction } from "../store";

export const releaseTemplateEffect = createEffect(
  (actions$ = inject(Actions), dialog: MatDialog = inject(MatDialog), service = inject(TemplateService)) => actions$.pipe(
    ofType(templateEditActions.releaseInit),
    switchMap(({ templateId }) => {
      const dialogData: ConfirmationDialogData = {
        title: 'Release Template?',
        messages: [ 'Are you sure you want to release this template?', 'Templates cannot be edited once they are released.' ],
        data: { templateId }
      };
      const dialogRef = dialog.open(ConfirmationDialogComponent, { data: dialogData });
      return dialogRef.afterClosed();
    }),
    filter(data => data !== undefined),
    switchMap(({ templateId }) => service.releaseTemplate(templateId)),
    map(template => template ? templateEditActions.releaseSuccess({ template }) : emptyAction())
  ),
  { functional: true }
);
