import { ExecuteTemplateData, FusionClient } from '@ssi/fusion';
import { UtilityService } from './utility.service';
import { SessionEvent, SessionRecord } from '../app.models';
import { Injectable } from '@angular/core';
import { FusionTemplates } from '../app.constants';

@Injectable({ providedIn: 'root' })
export class SessionService {
  processing = false;

  constructor(private readonly utilityService: UtilityService,
              private readonly fusionClient: FusionClient) { }

  recordSessionEvent(idToken: string, event: SessionEvent): Promise<SessionRecord> {
    if (this.processing) return Promise.resolve({} as SessionRecord);
    this.processing = true;
    const firstName = this.utilityService.getTokenProperty(idToken, 'given_name');
    const lastName = this.utilityService.getTokenProperty(idToken, 'family_name');
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.UserSession,
      parameters: {
        userUuid: { type: 'varchar', value: this.utilityService.getTokenProperty(idToken, 'sub')!, scope: 'sql' },
        name: { type: 'varchar', value: `${firstName} ${lastName}`.trim(), scope: 'sql' },
        eventType: { type: 'varchar', value: event, scope: 'sql' },
        email: { type: 'varchar', value: this.utilityService.getTokenProperty(idToken, 'email')!, scope: 'sql' }
      }
    };
    return this.fusionClient.send<SessionRecord>(templateData).then(res => {
      this.processing = false;
      return res.data!;
    });
  }
}

