import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { map, withLatestFrom } from "rxjs";
import { templateEditFeature } from "../template-edit/template-edit";

export const duplicateParameterEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(templateParametersActions.duplicateParameter),
      withLatestFrom(store.select(templateParametersFeature.selectUserParameterItemList),
        store.select(templateParametersFeature.selectNormalParameterItemList),
        store.select(templateEditFeature.selectTemplate)),
      map(([ action, userParams, normalParams, template ]) => {
        const { info } = action;
        const duplicatedItem = info.paramType === 'user'
          ? { ...userParams[info.index] } : { ...normalParams[info.index] };
        duplicatedItem.name = `${duplicatedItem.name}_copy`;
        const newUserParams = info.paramType === 'user' ? [ ...userParams, duplicatedItem ] : [ ...userParams ];
        const newNormalParams = info.paramType === 'normal' ? [ ...normalParams, duplicatedItem ] : [ ...normalParams ];

        return templateParametersActions.updateParametersInit({
          info: {
            templateId: template?.templateId || '',
            userParameterItemList: newUserParams,
            normalParameterItemList: newNormalParams
          }
        });
      })),
  { functional: true }
);
