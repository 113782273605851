import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilityService {
  timestamp(): number {
    return (new Date()).getTime();
  }

  getTokenExpiration(token: string): number {
    try {
      const expString = this.getTokenProperty(token, 'exp');
      return expString === undefined ? 0 : parseInt(expString) * 1000;
    } catch(e) {
      return 0;
    }
  }

  getTokenProperty(token: string, key: string): string | undefined {
    try {
      const [_, body, __] = token.split('.');
      const parsedBody = JSON.parse(atob(body));
      const keys = Object.keys(parsedBody);
      return keys.includes(key) ? `${parsedBody[key]}` : undefined;
    } catch(e) {
      return undefined;
    }
  }

  encodeText(text: string): Uint8Array {
    return (new TextEncoder()).encode(text);
  }

  decodeText(input: Uint8Array): string {
    return (new TextDecoder()).decode(input);
  }

  uuid(): string {
    if (window.crypto) return window.crypto.randomUUID();
    throw new Error('no crypto library found');
  }
}
