import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  title: string;
  messages: string[];
  data: unknown | undefined;

  constructor(private readonly dialogRef: MatDialogRef<ConfirmationDialogData>,
              @Inject(MAT_DIALOG_DATA) private dialogData: ConfirmationDialogData) {
    this.title = dialogData.title || 'Confirm Action';
    this.messages = dialogData.messages || ['Are you sure you want to perform this action?'];
    this.data = dialogData.data;
  }

  handleClose(returnData: boolean) {
    this.dialogRef.close(returnData ? this.data : undefined);
  }
}

export interface ConfirmationDialogData {
  title?: string;
  messages?: string[];
  data?: unknown;
}
