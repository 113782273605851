import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { UserTemplateParametersService } from "../../services/user-template-parameters.service";
import { templateEditActions } from "../template-edit/template-edit";
import { map, switchMap } from "rxjs";
import { templateParametersActions } from "./template-parameters";

export const loadSavedParametersEffect = createEffect(
  (actions$ = inject(Actions), parametersService = inject(UserTemplateParametersService)) => actions$.pipe(
    ofType(templateEditActions.loadTemplateInit),
    switchMap(({ templateId, userId }) => parametersService.getSavedParameters(templateId, userId)),
    map(items => {
      const normalParameterItemList = items.filter(item => !(item.name.startsWith('$') && item.name.endsWith('$')));
      const userParameterItemList = items.filter(item => (item.name.startsWith('$') && item.name.endsWith('$')));
      return templateParametersActions.loadParametersSuccess({ normalParameterItemList, userParameterItemList });
    })
  ),
  { functional: true }
);
