import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { userActions } from "./user";
import { distinctUntilChanged, tap } from "rxjs";

export const unauthenticatedEffect = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(userActions.signInFailure, userActions.signOutSuccess),
      distinctUntilChanged(),
      tap(() => router.navigate([ '/home' ]))
    ), { functional: true });
