import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { produce } from "immer";
import { loadUserEffect } from "./load-user.effect";
import { signInEffect } from "./sign-in.effect";
import { signOutEffect } from "./sign-out.effect";
import { unauthenticatedEffect } from "./unauthenticated.effect";
import { signInSuccessEffect } from "./sign-in-success.effect";
import { UserState } from "./user.types";

export const userActions = createActionGroup({
  source: 'User',
  events: {
    'Load': emptyProps(),
    'Load Success': props<{ userUuid: string }>(),
    'Sign In': emptyProps(),
    'Sign In Success': props<{ authCode: string }>(),
    'Sign In Failure': emptyProps(),
    'Sign Out': emptyProps(),
    'Sign Out Success': emptyProps(),
  }
});

const defaultUserState: UserState = { isSignedIn: false, userUuid: undefined };


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const userFeature = createFeature({
  name: 'user',
  reducer: createReducer(
    defaultUserState,
    on(userActions.loadSuccess, (state, { userUuid }) =>
      produce(state, draft => {
        draft.userUuid = userUuid;
        draft.isSignedIn = true;
      })),
    on(userActions.signOutSuccess, state =>
      produce(state, draft => {
        draft.userUuid = undefined;
        draft.isSignedIn = false;
      }))
  )
});
export const userEffects = { loadUserEffect, signInEffect, signOutEffect, unauthenticatedEffect, signInSuccessEffect };
