import { TemplateListModel } from "@ssi/fusion";
import { sortBy, isEmpty } from "lodash";
import { TemplateSearch, TemplateSort } from "./template-list.types";

export const filterTemplateList = (list: TemplateListModel[], search: TemplateSearch, sort: TemplateSort): TemplateListModel[] => {
  const searchKeys = Object.keys(search);
  const filteredList = list.filter(item => {
    // search by name or template ID
    const nameFilter1 = !searchKeys.includes('byNameSearch')
      ? true : item.name.toLowerCase().includes(search.byNameSearch!.toLowerCase());
    const nameFilter2 = !searchKeys.includes('byNameSearch')
      ? true : item.templateId.toLowerCase().includes(search.byNameSearch!.toLowerCase());
    const nameFilter = (nameFilter1 || nameFilter2);

    const contentTypeFilter = !searchKeys.includes('byContentType')
      ? true : item.contentType === search.byContentType;
    const descriptionFilter = !searchKeys.includes('byDescriptionSearch')
      ? true : item.description.toLowerCase().includes(search.byDescriptionSearch!.toLowerCase());
    const userTypeFilter = !searchKeys.includes('byUserType')
      ? true : item.permissions.allowedUserTypes.includes(search.byUserType!);
    const templateTypeFilter = !searchKeys.includes('byQueryType')
      ? true : item.type === search.byQueryType;

    let ownerFilter = true;
    if (searchKeys.includes('byOwner')) {
      switch (search.byOwner) {
        case 'all':
          ownerFilter = true;
          break;
        case 'none':
          ownerFilter = isEmpty(item.ownerName);
          break;
        default:
          ownerFilter = item.ownerName === search.byOwner;
          break;
      }
    }

    let releaseStatusFilter = true;
    if (searchKeys.includes('byReleaseStatus')) {
      switch (search.byReleaseStatus) {
        case 'releasedOnly':
          releaseStatusFilter = item.releasedAt > 0;
          break;
        case 'unreleasedOnly':
          releaseStatusFilter = item.releasedAt <= 0;
          break;
        default:
          releaseStatusFilter = true;
      }
    }

    return contentTypeFilter && nameFilter && ownerFilter && descriptionFilter
      && templateTypeFilter && releaseStatusFilter && userTypeFilter;
  });
  const sortedAscending = sortBy(filteredList, [ sort.sortField ]);
  return sort.direction === 'ascending' ? sortedAscending : sortedAscending.reverse();
};
