import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { ExecuteTemplateData, FusionClient, TemplateParams } from "@ssi/fusion";
import { Store } from "@ngrx/store";
import { templateEditActions, templateEditFeature } from "./template-edit";
import { map, switchMap, withLatestFrom } from "rxjs";
import { templateParametersFeature } from "../template-parameters/template-parameters";
import { buildTemplateParams } from "../template-parameters/template-parameters.types";

export const runQueryEffect = createEffect(
  (actions$ = inject(Actions), fusionClient = inject(FusionClient), store = inject(Store)) => {
    return actions$.pipe(
      ofType(templateEditActions.runQueryInit),
      withLatestFrom(store.select(templateEditFeature.selectTemplate),
        store.select(templateParametersFeature.selectNormalParameterItemList),
        store.select(templateParametersFeature.selectUserParameterItemList),
        store.select(templateEditFeature.selectCompanyUuid)),
      switchMap(([ _, template, normalParams, userParams, companyUuid ]) => {
        const templateParams: TemplateParams = buildTemplateParams([ ...normalParams, ...userParams ], true);
        const data: ExecuteTemplateData = {
          templateId: template?.templateId || '', parameters: templateParams, companyUuid
        };
        return fusionClient.send(data);
      }),
      map(response => templateEditActions.runQuerySuccess({ response }))
    );
  },
  { functional: true }
);
