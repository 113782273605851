import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthTokenService } from "../services/auth-token.service";

@Injectable({ providedIn: 'root' })
export class UnrestrictedGuard implements CanActivate {
  constructor(private readonly authTokenService: AuthTokenService, private readonly router: Router) { }

  canActivate(): Promise<boolean | UrlTree> {
    return this.authTokenService.getIdToken().then(
      () => this.router.createUrlTree(['/template-list']),
      () => true
    );
  }
}
