import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { templateParametersActions, templateParametersFeature } from "./template-parameters";
import { map, withLatestFrom } from "rxjs";
import { templateEditFeature } from "../template-edit/template-edit";

export const updateUserParameterEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(templateParametersActions.updateUserParameters),
      withLatestFrom(store.select(templateParametersFeature.selectNormalParameterItemList),
        store.select(templateEditFeature.selectTemplate)),
      map(([ action, normalParams, template ]) =>
        templateParametersActions.updateParametersInit({
          info: {
            templateId: template?.templateId || '',
            normalParameterItemList: normalParams,
            userParameterItemList: action.items
          }
        }))
    );
  },
  { functional: true }
);
