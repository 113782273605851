import { Component } from '@angular/core';
import { Store } from "@ngrx/store";
import { userActions } from "../../store/user/user";

@Component({
  selector: 'app-post-sign-out',
  templateUrl: './post-sign-out.component.html',
  styleUrls: ['./post-sign-out.component.scss']
})
export class PostSignOutComponent {

  constructor(private readonly store: Store) {
    this.store.dispatch(userActions.signOutSuccess());
  }
}
