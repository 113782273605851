import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { TemplateService } from "../../services/template.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { templateListActions } from "./template-list";
import { catchError, EMPTY, map, switchMap, tap } from "rxjs";

export const addEffect = createEffect(
  (actions$ = inject(Actions), templateService = inject(TemplateService),
   matSnackBar = inject(MatSnackBar), router = inject(Router)) =>
    actions$.pipe(
      ofType(templateListActions.add),
      switchMap(({ userUuid }) => {
        return templateService.addTemplate(userUuid);
      }),
      tap(template => {
        matSnackBar.open('Template successfully created', 'Close', { duration: 2000 });
        router.navigate([ '/', 'edit-template', template?.templateId ]).then();
      }),
      switchMap(() => templateService.listTemplates()),
      map(list => templateListActions.fetchSuccess({ list })),
      catchError(() => {
        matSnackBar.open('An error occurred creating the template', 'Close', { duration: 2000 });
        return EMPTY;
      })
    ), { functional: true });
