import { ParameterItem, TemplateNumberValue, TemplateParams, TemplateStringValue } from "@ssi/fusion";

export interface SpecifyParamInfo {
  paramType: 'normal' | 'user';
  index: number;
}

export interface ChangeParamStatusInfo extends SpecifyParamInfo {
  enabled: boolean;
}

export interface UpdateParamsInfo {
  templateId: string;
  normalParameterItemList: ParameterItem[];
  userParameterItemList: ParameterItem[];
}

export const buildTemplateParams = (items: ParameterItem[], includeUserParams = false): TemplateParams => {
  const newParams: TemplateParams = {};
  items
    .filter(item => item.enabled && Boolean(item.name))
    .filter(item => includeUserParams ? true : !(item.name.startsWith('$') && item.name.endsWith('$')))
    .forEach(item => {
      if ([ 'varchar', 'text', 'uuid', 'search' ].includes(item.type)) {
        newParams[item.name] = {
          type: item.type, value: item.value,
          scope: item.scope
        } as TemplateStringValue;
      } else {
        newParams[item.name] = {
          type: item.type, value: parseFloat(item.value),
          scope: item.scope
        } as TemplateNumberValue;
      }
    });
  return newParams;
};

export interface TemplateParametersState {
  normalParameterItemList: ParameterItem[];
  userParameterItemList: ParameterItem[];
}
