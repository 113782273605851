import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { UserTemplateParametersService } from "../../services/user-template-parameters.service";
import { templateParametersActions } from "./template-parameters";
import { forkJoin, map, of, switchMap, withLatestFrom } from "rxjs";
import { Store } from '@ngrx/store';
import { userFeature } from '../user/user';

export const updateParametersInit = createEffect(
  (actions$ = inject(Actions), service = inject(UserTemplateParametersService), store = inject(Store)) => {
    return actions$.pipe(
      ofType(templateParametersActions.updateParametersInit),
      withLatestFrom(store.select(userFeature.selectUserUuid)),
      switchMap(([{ info }, userId]) => {
        const paramList = [...info.normalParameterItemList, ...info.userParameterItemList];
        const paramResult = service.saveParameters(info.templateId, userId!, paramList);
        return forkJoin({ info: of(info), paramResult });
      }),
      map(({ info }) => {
        return templateParametersActions.loadParametersSuccess({
          normalParameterItemList: info.normalParameterItemList,
          userParameterItemList: info.userParameterItemList
        });
      })
    );
  },
  { functional: true }
);
