<div class="parameter-dialog-wrapper">
  <div class="title">
    <h2 *ngIf="!data">Add Parameter</h2>
    <h2 *ngIf="data">Edit Parameter</h2>
  </div>
  <form [formGroup]="formGroup">
    <div class="control">
      <mat-radio-group formControlName="mode">
        <div class="radio">
          <mat-radio-button value="normal">
            <span class="radio-option">Normal</span>
          </mat-radio-button>
          <mat-radio-button value="user">
            <span class="radio-option">User</span>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div class="control">
      <mat-form-field *ngIf="isNormalParameter()">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Parameter Name" formControlName="name">
      </mat-form-field>
      <mat-form-field *ngIf="!isNormalParameter()">
        <mat-label>Name</mat-label>
        <mat-select formControlName="name">
          <mat-option *ngFor="let variableName of variableNameList" [value]="variableName">{{variableName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field *ngIf="isNormalParameter()">
        <mat-label>SQL Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let paramType of paramTypeList" [value]="paramType">{{paramType}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput placeholder="Parameter Value" formControlName="value" >
      </mat-form-field>
    </div>
    <div class="control">
      <mat-form-field *ngIf="isNormalParameter()">
        <mat-label>Parameter Scope</mat-label>
        <mat-select formControlName="scope">
          <mat-option *ngFor="let scope of paramScopeList" [value]="scope.value">{{scope.text}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <p *ngIf="isNormalParameter()">Normal parameters are SQL and EJS parameters that must be provided by the fusion client to run the given query.</p>
    <p *ngIf="!isNormalParameter()">User parameters are properties of the authenticated user that is invoking the fusion template. These are can be simple token properties like user ID, but also derived values like allowed agvance location ID's. Because your developer user token does not contain these values, you must provide them here to test the template. However, these parameters should never be used in the SKY app and will be ignored if the template is invoked by a non-developer user.</p>

    <div class="actions">
      <button mat-stroked-button color="warn" (click)="close(false)">Cancel</button>
      <button mat-stroked-button color="primary" [disabled]="formGroup.invalid" (click)="close(true)">Save</button>
    </div>
  </form>
</div>
