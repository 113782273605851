import { Injectable, Injector } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

import { Cognito, Messages, Storage } from "../app.constants";
import { UtilityService } from "./utility.service";
import { BrowserStorage, CognitoTokenResponse, UserInfo } from "../app.models";
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class AuthTokenService {

  constructor(private readonly appStorage: BrowserStorage,
              private readonly utilityService: UtilityService,
              private readonly httpClient: HttpClient,
              private readonly injector: Injector) { }

  getIdToken(): Promise<string> {
    const token = this.appStorage.getItem(Storage.IdToken);

    if (token) {
      const expiration = this.utilityService.getTokenExpiration(token);
      if (expiration > this.utilityService.timestamp()) return Promise.resolve(token);
    }

    const refreshToken = this.appStorage.getItem(Storage.RefreshToken);
    if (!token && !refreshToken) return Promise.reject(new Error(Messages.NoToken));
    this.appStorage.removeItem(Storage.IdToken);
    this.appStorage.removeItem(Storage.AccessToken);

    const currentUrl = new URL(window.location.toString());
    const redirectUri = `${currentUrl.origin}/post-sign-in`;

    const cognitoUrl = new URL(`${Cognito.url}/oauth2/token`);
    const searchParams = new URLSearchParams();
    searchParams.set('grant_type', 'refresh_token');
    searchParams.set('client_id', Cognito.clientId);
    searchParams.set('scope', 'openid email profile');
    searchParams.set('redirect_uri', redirectUri);
    searchParams.set('refresh_token', refreshToken || '');

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return firstValueFrom(this.httpClient.post(cognitoUrl.toString(), searchParams, { headers })).then(
      data => {
        const { id_token, access_token } = data as CognitoTokenResponse;
        this.appStorage.setItem(Storage.IdToken, id_token);
        this.appStorage.setItem(Storage.AccessToken, access_token);
        /**
         * Because the auth token service is part of the construction of the fusion
         * client factory, you will get a circular reference error if you import
         * a service that uses the fusion client. We are using the injector here
         * to avoid that error with the session service
         */
        const sessionService = this.injector.get(SessionService);
        sessionService.recordSessionEvent(id_token, 'refresh').then();
        return id_token;
      }
    );
  }

  getUserInfo(): Promise<UserInfo> {
    return this.getIdToken().then(token => {
      const userUuid = this.utilityService.getTokenProperty(token, 'sub') || '';
      const email = this.utilityService.getTokenProperty(token, 'email') || '';
      return { userUuid, email };
    });
  }

}
