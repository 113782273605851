import { Observable } from "rxjs";

export type FusionEnvironment = 'development' | 'production' | 'test';

export abstract class BrowserStorage {
  abstract readonly length: number;

  abstract clear(): void;

  abstract getItem(key: string): string | null;

  abstract setItem(key: string, value: string): void;

  abstract key(index: number): string | null;

  abstract removeItem(key: string): void;
}

export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): Observable<boolean>;
}

export abstract class BrowserLocation {
  abstract replace(path: string): void;
  abstract toString(): string;
}

export abstract class BrowserWindow {
  abstract fetch(input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response>;
}

export interface CognitoTokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
}

export interface UserInfo {
  userUuid: string;
  email: string;
}

export type SessionEvent = 'sign-in' | 'refresh';

export interface SessionRecord {
  id: string;
  userUuid: string;
  name: string;
  createdAt: number;
  eventType: SessionEvent;
}
