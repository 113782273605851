import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { TemplateAnalyticsService } from "../../pages/analytics/services/template-analytics.service";
import { analyticsUsageActions } from "./analytics-usage";
import { map, switchMap } from "rxjs";

export const fetchRawDataEffect = createEffect(
  (actions$ = inject(Actions), analyticsService = inject(TemplateAnalyticsService)) => {
    return actions$.pipe(
      ofType(analyticsUsageActions.updateRawFilters),
      map(action => action.filters),
      switchMap(filters => analyticsService.getRawData(filters)),
      map(info => analyticsUsageActions.updateRawDataSuccess({ info }))
    );
  },
  { functional: true }
);
