import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map, switchMap } from "rxjs";
import { Messages, SnackbarDuration } from "../app.constants";
import { emptyAction } from "./store";
import { createActionGroup, props } from "@ngrx/store";
import { Router } from "@angular/router";

export const sharedActions = createActionGroup({
  source: 'Shared',
  events: {
    'Copy To Clipboard': props<{ value: string, message: string }>(),
    'Navigate': props<{ paths: string[] }>()
  }
});

const copyToClipboardEffect = createEffect(
  (actions$ = inject(Actions), clipboard = inject(Clipboard), snackbar = inject(MatSnackBar)) => {
    return actions$.pipe(
      ofType(sharedActions.copyToClipboard),
      switchMap(({ value, message }) => {
        const msg = message || Messages.ClipboardCopy;
        clipboard.copy(value);
        const ref = snackbar.open(msg, 'Close', { duration: SnackbarDuration });
        return ref.afterOpened();
      }),
      map(() => emptyAction())
    );
  },
  { functional: true }
);

const navigateEffect = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => {
    return actions$.pipe(
      ofType(sharedActions.navigate),
      switchMap(({ paths }) => router.navigate(paths)),
      map(() => emptyAction())
    );
  }, { functional: true }
);

export const sharedEffects = { copyToClipboardEffect, navigateEffect };
