import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { ParameterItem } from "@ssi/fusion";
import { produce } from "immer";
import { autofillNormalParametersEffect } from "./autofill-normal-parameters.effect";
import { updateNormalParametersEffect } from "./update-normal-parameters.effect";
import { loadSavedParametersEffect } from "./load-saved-parameters.effect";
import { addParameterEffect } from "./add-parameter.effect";
import { updateParameterStatusEffect } from "./update-parameter-status.effect";
import { updateParametersInit } from "./update-parameters.init";
import { updateUserParameterEffect } from "./update-user-parameter.effect";
import { deleteParameterEffect } from "./delete-parameter.effect";
import { duplicateParameterEffect } from "./duplicate-parameter.effect";
import { editParameterEffect } from "./edit-parameter.effect";
import {
  ChangeParamStatusInfo,
  SpecifyParamInfo,
  TemplateParametersState,
  UpdateParamsInfo
} from "./template-parameters.types";

export const templateParametersActions = createActionGroup({
  source: 'Template Parameters',
  events: {
    'Load Parameters Init': emptyProps(),
    'Load Parameters Success': props<{ normalParameterItemList: ParameterItem[], userParameterItemList: ParameterItem[] }>(),
    'Autofill Normal Parameters': emptyProps(),
    'Update Normal Parameters': props<{ items: ParameterItem[] }>(),
    'Update User Parameters': props<{ items: ParameterItem[] }>(),
    'Update Parameters Init': props<{ info: UpdateParamsInfo }>(),
    'Add Parameter': emptyProps(),
    'Edit Parameter': props<{ info: SpecifyParamInfo }>(),
    'Update Parameter Status': props<{ info: ChangeParamStatusInfo }>(),
    'Delete Parameter': props<{ info: SpecifyParamInfo }>(),
    'Duplicate Parameter': props<{ info: SpecifyParamInfo }>(),
  }
});

export const defaultTemplateParametersState: TemplateParametersState = {
  normalParameterItemList: [],
  userParameterItemList: []
};

export const templateParametersFeature = createFeature({
  name: 'templateParameters',
  reducer: createReducer(
    defaultTemplateParametersState,
    on(templateParametersActions.loadParametersSuccess, (state, data) => {
      return produce(state, draft => {
        draft.normalParameterItemList = data.normalParameterItemList;
        draft.userParameterItemList = data.userParameterItemList;
      });
    })
  )
});

export const templateParametersEffects = {
  autofillNormalParametersEffect, updateNormalParametersEffect,
  loadSavedParametersEffect, addParameterEffect, updateParameterStatusEffect, updateParametersInit,
  updateUserParameterEffect, deleteParameterEffect, duplicateParameterEffect, editParameterEffect
};
