import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { BrowserLocation, BrowserStorage, BrowserWindow, CognitoTokenResponse } from "../../app.models";
import { Router } from "@angular/router";
import { UtilityService } from "../../services/utility.service";
import { userActions } from "./user";
import { map, switchMap, tap } from "rxjs";
import { Cognito, Storage } from "../../app.constants";
import { SessionService } from '../../services/session.service';

export const signInSuccessEffect = createEffect(
  (actions$ = inject(Actions), browserLocation = inject(BrowserLocation), browserWindow = inject(BrowserWindow),
   browserStorage = inject(BrowserStorage), router = inject(Router), utilityService = inject(UtilityService),
   sessionService = inject(SessionService)) =>
    actions$.pipe(
      ofType(userActions.signInSuccess),
      switchMap(({ authCode }) => {
        const currentUrl = new URL(browserLocation.toString());
        const tokenUrl = new URL(`${Cognito.url}/oauth2/token`);
        const searchParams = new URLSearchParams();
        searchParams.set('grant_type', 'authorization_code');
        searchParams.set('client_id', Cognito.clientId);
        searchParams.set('scope', 'openid email profile');
        searchParams.set('redirect_uri', `${currentUrl.origin}/post-sign-in`);
        searchParams.set('code', authCode || '');

        return browserWindow.fetch(tokenUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: searchParams.toString()
        });
      }),
      switchMap(response => response.json()),
      tap((data: CognitoTokenResponse) => {
        browserStorage.setItem(Storage.IdToken, data.id_token);
        browserStorage.setItem(Storage.AccessToken, data.access_token);
        browserStorage.setItem(Storage.RefreshToken, data.refresh_token);
        sessionService.recordSessionEvent(data.id_token, 'sign-in');
        router.navigate([ '/template-list' ]).then();
      }),
      map((data: CognitoTokenResponse) => {
        const userUuid = utilityService.getTokenProperty(data.id_token, 'sub') || '';
        return userActions.loadSuccess({ userUuid });
      })
    ), { functional: true });
