import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ParameterItem, ParameterScope, PreProcessingFields } from "@ssi/fusion";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'app-template-parameter-dialog',
  templateUrl: './template-parameter-dialog.component.html',
  styleUrls: ['./template-parameter-dialog.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatRadioModule,
    CommonModule, MatInputModule, MatSelectModule, MatButtonModule]
})
export class TemplateParameterDialogComponent {
  formGroup: FormGroup;
  variableNameList: string[];
  paramScopeList: { text: string; value: ParameterScope }[];
  paramTypeList: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ParameterItem | undefined,
              private readonly dialogRef: MatDialogRef<TemplateParameterDialogComponent>) {
    this.variableNameList = Object.values(PreProcessingFields).map(val => `$${val}$`);
    this.paramTypeList = ['varchar', 'text', 'uuid', 'search', 'int', 'bigint', 'bit'];
    this.paramScopeList = [
      { text: 'Both SQL and EJS', value: 'both' },
      { text: 'SQL Only', value: 'sql' },
      { text: 'EJS Only', value: 'ejs' }
    ];
    const defaultItem: ParameterItem = { name: '', value: '', enabled: true, type: 'varchar', scope: 'both' };
    const item: ParameterItem = !data ? defaultItem : data;
    const mode = !data ? 'normal' : (data.name.startsWith('$') && data.name.endsWith('$')) ? 'user' : 'normal';
    this.formGroup = new FormGroup({
      enabled: new FormControl(item.enabled),
      mode: new FormControl(mode),
      name: new FormControl(item.name, [Validators.required]),
      value: new FormControl(item.value, [Validators.required]),
      type: new FormControl(item.type, [Validators.required]),
      scope: new FormControl(item.scope, [Validators.required]),
    });
  }

  isNormalParameter() {
    return this.formGroup.controls['mode'].value === 'normal';
  }

  close(sendData: boolean): void {
    const formData = this.formGroup.getRawValue() as ParameterItem & { mode: 'normal' | 'user' };
    if (formData.mode === 'user') {
      formData.type = 'varchar';
      formData.scope = 'both';
    }
    this.dialogRef.close(sendData ? formData : undefined);
  }
}
