import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { TemplateService } from '../../services/template.service';
import { templateListActions } from './template-list';
import { map, switchMap } from 'rxjs';

export const loadOwnersEffect = createEffect((actions$ = inject(Actions), templates = inject(TemplateService)) => {
  return actions$.pipe(
    ofType(templateListActions.fetchOwners),
    switchMap(() => templates.getOwnerInfo()),
    map(list => templateListActions.fetchOwnersSuccess({ list }))
  );

}, { functional: true });
