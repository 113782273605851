import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { TemplateService } from "../../services/template.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { templateEditActions, templateEditFeature } from "./template-edit";
import { map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { PatchTemplateData } from "@ssi/fusion";
import { defaults, isEqual } from "lodash";
import { Messages, SnackbarDuration } from "../../app.constants";
import { emptyAction } from "../store";

export const patchTemplateEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), service = inject(TemplateService), snackbar = inject(MatSnackBar)) => {
    return actions$.pipe(
      ofType(templateEditActions.patchTemplateInit),
      withLatestFrom(store.select(templateEditFeature.selectTemplate)),
      withLatestFrom(store.select(templateEditFeature.selectChanges)),
      switchMap(([ [ _, template ], changes ]) => {
        if (template === undefined) return of(undefined);
        const adjustedChanges = changes === undefined
          ? { templateId: template.templateId } as PatchTemplateData : changes;
        const alteredTemplate = defaults({ ...adjustedChanges }, { ...template });
        if (isEqual(alteredTemplate, template)) return of(template);
        return service.patchTemplate(adjustedChanges);
      }),
      tap(template => {
        if (!template) return;
        snackbar.open(Messages.TemplateInfoSaved, 'Close', { duration: SnackbarDuration });
      }),
      map(template => !template ? emptyAction() : templateEditActions.patchTemplateSuccess({ template }))
    );
  },
  { functional: true }
);
