<div class="confirm-wrapper">
  <div class="mat-headline-5">{{title}}</div>

  <div class="messages">
    <p class="mat-body-2" *ngFor="let message of messages">
      <span *ngIf="message">{{message}}</span>
      <span *ngIf="!message">&nbsp;</span>
    </p>
  </div>

  <div class="buttons">
    <button mat-flat-button color="warn" (click)="handleClose(false)">Cancel</button>
    <button mat-flat-button color="primary" (click)="handleClose(true)">Confirm</button>
  </div>
</div>
