import { Injectable } from '@angular/core';
import {
  ExecuteTemplateData,
  FusionClient,
  PatchTemplateData,
  SpecifyTemplateData,
  TemplateContentType,
  TemplateListModel,
  TemplateModel, TemplateParams
} from '@ssi/fusion';
import { isEmpty } from 'lodash';
import { FusionTemplates } from '../app.constants';
import { UtilityService } from './utility.service';
import { OwnerInfo } from '../pages/template-list/template-search/template-search.component';

@Injectable({ providedIn: 'root' })
export class TemplateService {

  constructor(private readonly fusionClient: FusionClient,
              private readonly utilityService: UtilityService) { }

  public listTemplates(): Promise<TemplateListModel[]> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.ListTemplates
    };
    const determineContentType = (content: string): TemplateContentType => {
      const contentTypeList: TemplateContentType[] = ['select', 'insert', 'update', 'delete'];
      const words = content
        .trim()
        .toLowerCase()
        .split(/\s+/)
        .map(word => contentTypeList.includes(word as any) ? word : '')
        .filter(item => item !== '');
      return words.length > 0 ? words[0] as TemplateContentType : 'other';
    };
    const convertTemplateModel = (template: TemplateModelWithOwnerInfo): TemplateListModel => {
      return {
        ...template,
        contentType: determineContentType(template.content)
      } as TemplateListModel;
    };
    return this.fusionClient.send<TemplateModelWithOwnerInfo[]>(templateData)
      .then(res => res.data || [] as TemplateModelWithOwnerInfo[])
      .then((items: TemplateModelWithOwnerInfo[]) => items.map(convertTemplateModel));
  };

  public getTemplate(templateId: string): Promise<TemplateModel> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.GetTemplate,
      parameters: {
        "templateId": { "type": "varchar", "value": templateId, "scope": "sql" }
      }
    };
    return this.fusionClient.send<TemplateModel>(templateData).then(result => result.data!);
  }

  public deleteTemplate(templateId: string, userUuid: string): Promise<TemplateModel> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.DeleteTemplate,
      parameters: {
        "templateId": { "type": "varchar", "value": templateId, "scope": "sql" },
        "userId": { "type": "varchar", "value": userUuid || '', "scope": "sql" }
      }
    };
    return this.fusionClient.send<TemplateModel>(templateData).then(result => result.data!);
  }

  public addTemplate(userUuid: string): Promise<TemplateModel | undefined> {
    const metadata = { authorUuid: userUuid };
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.InsertTemplate,
      parameters: {
        "templateId": { type: 'varchar', value: this.utilityService.uuid(), scope: 'sql' },
        "metadata": { type: 'varchar', value: JSON.stringify(metadata), scope: 'ejs' }
      }
    };
    return this.fusionClient.send<TemplateModel>(templateData).then(result => result!.data);
  }

  public async copyTemplate(data: SpecifyTemplateData, userUuid: string): Promise<TemplateModel | undefined> {
    const metadata = { authorUuid: userUuid };
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.CopyTemplate,
      parameters: {
        "newId": { type: 'varchar', value: this.utilityService.uuid(), scope: 'ejs' },
        "metadata": { type: 'varchar', value: JSON.stringify(metadata), scope: 'ejs' },
        "templateId": { type: 'varchar', value: data.templateId, scope: 'sql' },
      }
    };
    return this.fusionClient.send<TemplateModel>(templateData).then(result => result!.data);
  }

  public patchTemplate(data: PatchTemplateData): Promise<TemplateModel | undefined> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.PatchTemplate
    };
    const params: TemplateParams = {
      templateId: { type: 'varchar', value: data.templateId || '', scope: 'sql' }
    };
    const props = ['name', 'description', 'content', 'type', 'permissions', 'returnType', 'metadata'];
    const anyData = data as any;
    props.forEach(prop => {
      if (isEmpty(anyData[prop])) return;
      if (prop === 'permissions' || prop === 'metadata') {
        params[prop] = { type: 'varchar', value: JSON.stringify(anyData[prop]), scope: 'both' };
      } else {
        params[prop] = { type: 'varchar', value: anyData[prop], scope: 'both' };
      }
    });
    templateData.parameters = params;
    return this.fusionClient.send<TemplateModel>(templateData).then(result => result.ok ? result.data : undefined);
  }

  public releaseTemplate(templateId: string): Promise<TemplateModel | undefined> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.ReleaseTemplate,
      parameters: {
        "templateId": { "type": "varchar", "value": templateId, "scope": "sql" }
      }
    };
    return this.fusionClient.send<TemplateModel>(templateData)
      .then(response => response.ok ? response.data : undefined);
  }

  public getOwnerInfo(): Promise<OwnerInfo[]> {
    const templateData: ExecuteTemplateData = {
      templateId: FusionTemplates.TemplateOwnerList,
      cacheConfiguration: { key: 'OwnerList', duration: { value: 10, unit: 'minute' } }
    };
    return this.fusionClient.send<OwnerInfo[]>(templateData).then(res => res.data || []);
  }
}

interface TemplateModelWithOwnerInfo extends TemplateModel {
  ownerId: string;
  ownerName: string;
}
