import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { produce } from "immer";

import { AnalyticsRawInfo, PaginatedInfo } from "../../pages/analytics/analytics.types";
import {
  AnalyticsRawFilters,
  AnalyticsUsageFilters,
  AnalyticsUsageResult,
  AnalyticsUsageState
} from "./analytics-usage.types";
import { fetchUsageEffect } from "./fetch-usage.effect";
import { fetchApplicationsEffect } from "./fetch-applications.effect";
import { fetchRawDataEffect } from "./fetch-raw-data.effect";
import { enterAnalyticsRawDataRouteEffect } from "./enter-analytics-raw-data-route.effect";
import { fetchRawDataNextPageEffect } from "./fetch-raw-data-next-page.effect";
import { fetchUserTypesEffect } from "./fetch-user-types.effect";
import * as dayjs from "dayjs";

export const analyticsUsageActions = createActionGroup({
  source: 'Analytics Usage',
  events: {
    'Update Filters': props<{ filters: AnalyticsUsageFilters }>(),
    'Usage Results Success': props<{ usageResults: AnalyticsUsageResult[] }>(),
    'Load Applications Init': emptyProps(),
    'Load Applications Success': props<{ applications: string[] }>(),
    'Update Raw Filters': props<{ filters: AnalyticsRawFilters }>(),
    'Update Raw Data Success': props<{ info: PaginatedInfo<AnalyticsRawInfo> }>(),
    'Fetch Raw Data Next Page': emptyProps(),
    'Load User Types Init': emptyProps(),
    'Load User Types Success': props<{ types: string[] }>()
  }
});

export const defaultAnalyticsUsageState: AnalyticsUsageState = {
  filters: {
    name: '',
    releaseStatus: 'any',
    showExcluded: false,
    order: 'name_asc',
    dateRangeStart: dayjs().subtract(1, 'month').startOf('day').toDate(),
    dateRangeEnd: dayjs().startOf('day').toDate(),
    environment: 'any',
    application: 'any',
    resultType: 'any'
  },
  usageResults: [],
  applications: [],
  userTypes: [],
  usageStatsLoading: false,
  rawData: {
    filters: {
      order: 'invocationTime_desc',
      templateId: '',
      dateRangeStart: dayjs().subtract(1, 'month').startOf('day').toDate(),
      dateRangeEnd: dayjs().startOf('day').toDate(),
      userUuid: '',
      environment: 'any',
      application: 'any',
      resultType: 'any',
      userType: 'any'
    },
    results: [],
    loading: false,
    continuationToken: ''
  }
};

export const analyticsUsageFeature = createFeature({
  name: 'analyticsUsage',
  reducer: createReducer(
    defaultAnalyticsUsageState,
    on(analyticsUsageActions.updateFilters,
      (state, { filters }) => produce(state, draft => {
        draft.usageResults = [];
        draft.usageStatsLoading = true;
        draft.filters = filters;
      })),
    on(analyticsUsageActions.usageResultsSuccess,
      (state, { usageResults }) => produce(state, draft => {
        draft.usageResults = usageResults;
        draft.usageStatsLoading = false;
      })),
    on(analyticsUsageActions.loadApplicationsSuccess,
      (state, { applications }) => produce(state, draft => {
        draft.applications = applications;
      })),
    on(analyticsUsageActions.updateRawFilters,
      (state, { filters }) => produce(state, draft => {
        draft.rawData.filters = filters;
        draft.rawData.loading = true;
        draft.rawData.continuationToken = '';
        draft.rawData.results = [];
      })),
    on(analyticsUsageActions.fetchRawDataNextPage,
      (state) => produce(state, draft => {
        draft.rawData.continuationToken = '';
      })),
    on(analyticsUsageActions.updateRawDataSuccess,
      (state, { info }) => produce(state, draft => {
        draft.rawData.loading = false;
        draft.rawData.results = info.data;
        draft.rawData.continuationToken = info.continuationToken || '';
      })),
    on(analyticsUsageActions.loadUserTypesInit,
      (state) => produce(state, draft => {
        draft.userTypes = [];
      })),
    on(analyticsUsageActions.loadUserTypesSuccess,
      (state, { types }) => produce(state, draft => {
        draft.userTypes = types;
      })),
  )
});
export const analyticsUsageEffects = {
  fetchUsageEffect, fetchApplicationsEffect, fetchRawDataEffect, enterAnalyticsRawDataRouteEffect,
  fetchRawDataNextPageEffect, fetchUserTypesEffect
};
