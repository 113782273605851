import { Actions, createEffect, ofType } from "@ngrx/effects";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { getRouterSelectors, routerNavigatedAction } from "@ngrx/router-store";
import { combineLatestWith, distinctUntilChanged, filter, map, switchMap } from "rxjs";
import { Data, Params } from "@angular/router";
import { RouteNames } from "../../app.constants";
import { templateEditActions } from "./template-edit";
import { userFeature } from '../user/user';

export const enterTemplateEditRouteEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    const { selectRouteData, selectRouteParams } = getRouterSelectors();
    return actions$.pipe(
      ofType(routerNavigatedAction),
      distinctUntilChanged(),
      switchMap(() => store.select(selectRouteData)),
      filter((data: Data) => data['routeName'] === RouteNames.EditTemplate),
      switchMap(() => store.select(selectRouteParams)),
      combineLatestWith(store.select(userFeature.selectUserUuid)),
      map(([params, userId]: [Params, string | undefined]) => {
        return templateEditActions.loadTemplateInit({ templateId: params['id'], userId: userId || '' });
      })
    );
  },
  { functional: true }
);
